import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {};

const drugbookSlice = createSlice({
  name: "drugBook",
  initialState,
  reducers: {
    getDrugBookReceived(state, action) {},
    getDrugBookReceivedSuccess(state, action) {
      let drugBook = {
        drugBookReceived: Array.isArray(action.payload.drugBookReceived)
          ? [...action.payload.drugBookReceived]
          : [],
        totalCountReceived: action.payload.TotalCount || 0,
      };
      return { ...state, ...drugBook };
    },
    getDrugBookAdministered(state, action) {},
    getDrugBookAdministeredSuccess(state, action) {
      let drugBook = {
        drugBookAdministered: Array.isArray(action.payload.drugBookAdmin)
          ? [...action.payload.drugBookAdmin]
          : [],
        totalCountAdmin: action.payload.TotalCount || 0,
      };
      return { ...state, ...drugBook };
    },
    getDrugBookRemoved(state, action) {},
    getDrugBookRemovedSuccess(state, action) {
      let drugBook = {
        drugBookRemoved: Array.isArray(action.payload.drugBookRemoved)
          ? [...action.payload.drugBookRemoved]
          : [],
        totalCountRemoved: action.payload.TotalCount || 0,
      };
      return { ...state, ...drugBook };
    },

    addReceivedDrugBook(state, action) {},
    addReceivedDrugBookSuccess(state, action) {
        let drugBook = {
            drugBookReceived: [...action.payload],
        };
        return { ...state, ...drugBook };
      },
    addDrugBookAdministered(state, action) {},
    addDrugBookAdministeredSuccess(state, action) {
        let drugBook = {
            drugBookAdministered: [...action.payload],
        };
        return { ...state, ...drugBook };
      },
      addRemovedDrugBook(state, action) {},
      addRemovedDrugBookSuccess(state, action) {
          let drugBook = {
              drugBookRemoved: [...action.payload],
          };
          return { ...state, ...drugBook };
        }, 
  },
});

export const {
  getDrugBookReceived,
  getDrugBookReceivedSuccess,
  getDrugBookAdministered,
  getDrugBookAdministeredSuccess,
  addDrugBookAdministered,
  addDrugBookAdministeredSuccess,
  addReceivedDrugBook,
  addReceivedDrugBookSuccess,
  getDrugBookRemoved,
  getDrugBookRemovedSuccess,
  addRemovedDrugBook,
  addRemovedDrugBookSuccess,
} = drugbookSlice.actions;

export default drugbookSlice.reducer;

import { call, put } from 'redux-saga/effects';
import { getAcsToken } from './../api/communicationApi';
import { getAcsTokenSuccess } from '../reducers/communicationSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';


export function* getAcsTokenHandler(action: any): Generator<any, any, any> {
    const { id, navigate, redirectUrl } = action.payload;
    yield put(setLoader());
    const { response } = yield call(getAcsToken, { id: id });
    if (response) {        
      yield put(getAcsTokenSuccess({ ...response.data }));
      yield put(resetLoader());
    }
  }

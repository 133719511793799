import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  isLoaded: false,
};

const userRolesSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {
    getUserRoles(state, action) {},
    getUserRoleSuccess(state, action) {
      const rolesData = [...action.payload];

      let roles = rolesData.map((c) => ({
        value: c.Role,
        label: c.Role,
        Id: c.Id,
      }));

      let userRoles = {
        isLoaded: true,
        roles: roles,
      };

      return { ...state, ...userRoles };
    },
  },
});

export const { getUserRoles, getUserRoleSuccess } = userRolesSlice.actions;

export default userRolesSlice.reducer;

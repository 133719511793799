import { call, put } from 'redux-saga/effects';
import {
  getProductsApi,
  deleteProductApi,
  updateProductApi,
} from '../api/productsApi';
import { getProductsSuccess } from '../reducers/productSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';

export function* getProducsHandler(action: any): Generator<any, any, any> {
  
  const { data, type, history } = action.payload;

  const { response } = yield call(getProductsApi, {data});
  if (response) {
    yield put(getProductsSuccess(response.data));
  }
}

export function* deleteProductHandler(action: any): Generator<any, any, any> {
  const { data, id, history } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(deleteProductApi, { id: id });
  if (deleteResponse) {
    const { response } = yield call(getProductsApi, {data});
    if (response) {
      yield put(getProductsSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* updateProductHandler(action: any): Generator<any, any, any> {
  const {data, getProductsPayload} = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(updateProductApi, action.payload);
  if (deleteResponse) {

    const data = {
     ...getProductsPayload
    }
    const { response } = yield call(getProductsApi, {data});
    if (response) {
      yield put(getProductsSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

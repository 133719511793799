import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  isLoaded: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts(state, action) {},
    getProductsSuccess(state, action) {
      let products = {
        products: Array.isArray(action.payload.Products)
          ? [...action.payload.Products]
          : [],
        totalCount: action.payload.TotalCount || 0,
        isLoaded: true,
      };
      return { ...state, ...products };

    },
    deleteProduct(state, action) {},
    updateProduct(state, action) {},
  },
});

export const { getProducts, getProductsSuccess, deleteProduct, updateProduct } =
  productSlice.actions;

export default productSlice.reducer;

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import authentication from './authenticationSlice';
import users from '../reducers/userSlice';
import loader from './loaderSlice';
import clinic from './clinicsSlice';
import product from './productSlice';
import userRoles from '../reducers/userRolesSlice';
import appointment from '../reducers/appointmentSlice';
import patient from '../reducers/patientSlice';
import userClinic from '../reducers/userClinicSlice';
import treatmentPlan from '../reducers/treatmentPlanSlice';
import videoConsult from './videoConsultSlice';
import communication from '../reducers/communicationSlice';
import drugBook from '../reducers/drugbookSlice';
import report from '../reducers/reportSlice';
import adverseEvents from '../reducers/adverseEventsSlice'
import errorModal from '../reducers/errorModalSlice';

// Reducers
const rootReducer = combineReducers({
  form: formReducer,
  authentication,
  users,
  loader,
  clinic,
  product,
  userRoles,
  appointment,
  patient,
  userClinic,
  treatmentPlan,
  videoConsult,
  communication,
  drugBook,
  report,
  adverseEvents,
  errorModal
});

export default rootReducer;

import { LogLevel } from '@azure/msal-browser';
import { envEndPoints } from '../app/config';

export const msalConfig = {
  auth: {
    clientId: envEndPoints().clientId,
    authority: envEndPoints().authority,
    redirectUri: envEndPoints().redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [`api://${envEndPoints().clientId}/${envEndPoints().scope}`],
};

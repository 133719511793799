export const getDischargeSummaries = (request) => {

    let dischargeSummaries = [];
    if (!!request && !!request.dischargeSumaries > 0) {
        request.dischargeSumaries.forEach((summary) => {
            dischargeSummaries.push({
                treatmentArea: summary.treatmentArea.label,
                batchNumber: summary.batchNumber,
                productName: summary.productName.label,
                clinicId: summary.clinicId,
                patientId: summary.patientId,
                administeredUserId: summary.administeredUserId,
                volume: summary.volume,
                administeredDate: summary.administeredDate
            });
        });
    }


    return{
        notes: request.notes,
        appointmentId: request.appointmentId,
        dischargeSumaries: dischargeSummaries,
        prpThreadsSummary: request.prpThreadsSummary,
    }
};
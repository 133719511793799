import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Main from '../layouts/Main/Main';
import NoAccess from '../pages/Auth/NoAccess/NoAccess';

const Router = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main {...props} />}>
          <Route index element={<NoAccess />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { createSlice } from '@reduxjs/toolkit';


// Define the initial state using that type
const initialState = {
  isError: false,
  errorMessage: '',
};

const errorModalSlice = createSlice({
  name: 'Loader',
  initialState,
  reducers: {
    setError(state, action) {
      return { ...state, ...action.payload };
    },
    resetError(state) {
      return { ...state, ...initialState };
    },
  },
});

export const { setError, resetError } = errorModalSlice.actions;

export default errorModalSlice.reducer;

import { post, get, del } from './restApi';
import { envEndPoints } from '../config';

  export const searchAppointments = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/searchAppointments`,
    data: req.data
  });

export const deleteAppointnment = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/apppointment/${req.id}`,
  });

export const getAppointment = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/appointment/appointmentdetails/${req.id}`,
  });

export const createAppointment = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/getAppointmentList`,
    data: req,
  });

export const saveAppointmentAdditionalDetails = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/createAdditionalDetails/${req.userId}/${
      req.id
    }`,
    data: req.data,
  });

export const confirmAppointmentTreatmentsAPI = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/ConfirmAppointmentTreatments`,
    data: req,
  });

export const refreshAppointment = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/refreshAppointment`,
    data: req.data,
  });

  export const createLocalAppointment = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/createAppointment`,
    data: req.data,
  });

export const confirmTreatment = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/ConfirmTreatment/${req.appointmentId}/${req.treatmentPlanId}`,
  });

  export const submitDetails = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/submit/${req.appointmentId}`,
    data: req.data,
  });

  export const saveDischargeSummary = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/dischargeSummary`,
    data: req.data,
  });


  export const uploadAppointmentFiles = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/upload`,
    data: req.data,
  });

  export const saveMedicalHistory = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/appointment/createMedicalHistory/${req.patientId}/${req.appointmentId}/${req.userId}/${req.medicalHistoryId}`,
    data: req.medicalHistoryData,
  });

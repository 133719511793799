import { httpClient } from './httpClient';

export const get = (config: any) =>
  httpClient({
    ...config,
    method: 'get',
  });

export const post = (config: any) =>
  httpClient({
    ...config,
    method: 'post',
  });

export const put = (config: any) =>
  httpClient({
    ...config,
    method: 'put',
  });

export const del = (config: any) =>
  httpClient({
    ...config,
    method: 'delete',
  });

import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getBillingReport(state, action) {},
    getBillingReportSuccess(state, action) {
      const response = action.payload.response;
      const billingReport = {
        billingReport: response.Report,
        billingCount: response.TotalCount,
        treatmentPlansApprovedByPrescriber: response.TreatmentPlansApprovedByPrescriber,
        numberOfNurses: response.NumberOfNurses,
        numberOfClinics: response.NumberOfClinics,
      };
      return { ...state, ...billingReport };
    },
    getCallPerformanceReport(state, action) {},
    getCallPerformanceReportSuccess(state, action) {
      const response = action.payload.response;
      const callPerformanceReport = {
        callPerformanceReport: response.Report,
        callPerformanceCount: response.TotalCount,
        numberOfCalls: response.NumberOfCalls,
        avgWaitTime: response.StrAvgWaitTime,
        avgCallTime: response.StrAvgCallTime,
      };
      return { ...state, ...callPerformanceReport };
    },
    emailBillingReport(state, action) {},
    emailCallPerformanceReport(state, action) {},
  },
});

export const { getBillingReport, getBillingReportSuccess, getCallPerformanceReport, getCallPerformanceReportSuccess, emailBillingReport,  emailCallPerformanceReport} =
reportSlice.actions;

export default reportSlice.reducer;

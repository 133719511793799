import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2",
    },
    secondary: {
        main: "#ce93d8"
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ffa726",
    },
    info: {
      main: "#29b6f6",
    },
    success: {
      main: "#66bb6a",
    },
    tertiary: {
      main: "#1E293B",
    },
    light: {
      main: "#ffffff",
    },
    dark: {
      main: "#444444",
    },
    grey: {
      main: "#616161"
    }
  },
  typography:{
    fontFamily: `'Manrope', sans-serif`
  }
});

export default theme;

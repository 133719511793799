import { post, get } from "./restApi";
import { envEndPoints } from "../config";

export const getDrugBookReceived = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/getReceived`,
    data: req.data, 
  });

export const getDrugBookRemoved = (req:any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/getRemoved`,
    data: req.data,
  });

export const getDrugBookAdministered = (req:any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/getAdministered`,
    data: req.data,
  });

export const addDrugBookAdministered = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/administered`,
    data: req,
  });

export const addReceivedDrugBook = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/received`,
    data: req,
  });

  export const addRemovedDrugBook = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/drugbook/removed`,
    data: req,
  });

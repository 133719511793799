import axios from 'axios';
import { resetLoader } from '../reducers/loaderSlice';
import store from '../store';

import { reqConfig, resConfig } from './localServer';

const devEnvironmnet = 'localhost:3006';

axios.interceptors.request.use((config) => {
  let requestConfig = config;
  //To handle dev environment.
  if (requestConfig.url?.trim().toLowerCase().includes(devEnvironmnet)) {
    requestConfig = reqConfig(requestConfig);
    requestConfig.headers = {
      ...config.headers,
      'Content-type': 'application/json',
    };
    return requestConfig;
  }

  //To skip adding token for getroles.
  if (
    requestConfig.url?.trim().toLowerCase().includes('/getuserrole') &&
    !requestConfig.url?.trim().toLowerCase().includes('/getuserroles')
  ) {
    return requestConfig;
  }

  //Add common headers

  //const accessToken = store.getState().authentication.accessToken;

  const accessToken = window.sessionStorage.getItem('accessToken');
  requestConfig.headers = {
    ...config.headers,
    'Content-type':  requestConfig.url?.trim().toLowerCase().includes('/appointment/submit/')? 'multipart/form-data': 'application/json',
    Authorization: `bearer ${accessToken}`,
  };

  return requestConfig;
});

//Handling response headers for dev environment Mock API's.
axios.interceptors.response.use((response) => {
  if (response.config.url?.toLowerCase().includes(devEnvironmnet)) {
    return resConfig(response);
  } else {
    return response;
  }
});

function onError(error: any, errorHandler: any) {
  const { response } = error;
  let errorMessage = `${response.status} : System error - please contact support.`;
  if (errorHandler) {
    errorMessage = errorHandler(response);
  }
  store.dispatch(resetLoader());
  return false;
}

export const httpClient = (config: any) => {
  const req = {
    method: config.method,
    url: config.url,
    params: config.params,
    headers: config.headers,
    data: config.data,
  };

  return axios(req)
    .then((response) => ({ response }))
    .catch((error) => onError(error, config.errorHandler));
};

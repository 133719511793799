import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  isLoaded: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser(state, action) {
      const { reqData, type, history } = action.payload;
    },
    saveUserSuccess(state, action) {
      const data = action.payload;

      return { ...state, ...data };
    },
    userUnmount(state) {
      return { ...initialState };
    },
    getUser(state, action) {
      const { history, redirectUrl, userId, userProfile } = action.payload;
    },
    getUserProfile(state, action) {
      const { history, redirectUrl, orgId, userName } = action.payload;
    },
    getUsersList(state, action) {},
    getUserListSuccess(state, action) {
      let users = {
        isLoaded: true,
        users: [...action.payload],
      };

      return { ...state, ...users };
    },
    getAvailablePrescribersSuccess(state,action){
      let availablePrescribers = {
        ...action.payload,
      };
      return { ...state, ...availablePrescribers};
    },
    deleteUser(state, action) {},
    updateUser(state, action) {},
    addUser(state, action) {},
    getAvailablePrescribers(state, action) {},
    getNurses(state, action) {},
    getNursesSuccess(state, action) {      
      let nurses = {
        nurses: action.payload.nurses        
      }
      return { ...state, ...nurses};
    }
  },
});

export const {
  saveUser,
  saveUserSuccess,
  userUnmount,
  getUser,
  getUserProfile,
  getUsersList,
  getUserListSuccess,
  deleteUser,
  updateUser,
  addUser,
  getAvailablePrescribers,
  getAvailablePrescribersSuccess,
  getNurses,
  getNursesSuccess
} = userSlice.actions;

export default userSlice.reducer;

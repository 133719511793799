import React from 'react';
import './Heading.css';

const Heading = (props) => {
  const { title, subHeading } = props;
  return (
    <div className="heading-wrapper">
      <div className="flex justify-center">
        <div className="heading">{title}</div>
      </div>
      {subHeading ? <div className="sub-heading">{subHeading}</div> : null}
    </div>
  )
};

export default Heading;
import { post } from './restApi';
import { envEndPoints } from '../config';

export const getBillingReport = (req: any = {}) =>
  post({
    url: `${envEndPoints().baseUrl}/report/billingReport`,
    data: req,
  });

  export const getCallPerformanceReport = (req: any = {}) =>
  post({
    url: `${envEndPoints().baseUrl}/report/callPerformance`,
    data: req,
  });


  export const emailBillingReport = (req: any = {}) =>
  post({
    url: `${envEndPoints().baseUrl}/report/emailBillingReport`,
    data: req,
  });

  export const emailCallPerformance = (req: any = {}) =>
  post({
    url: `${envEndPoints().baseUrl}/report/emailCallPerformance`,
    data: req,
  });

import { call, put } from 'redux-saga/effects';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { getBillingReport, getCallPerformanceReport, emailBillingReport, emailCallPerformance } from '../api/reportApi';
import { getBillingReportSuccess, getCallPerformanceReportSuccess } from '../reducers/reportSlice';
import store from '../store';


export function* getBillingReportHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  const userEmailAddress = store.getState().authentication.userId;

  yield put(setLoader());
  const apiResponse = yield call(getBillingReport, {...action.payload, UserEmailId: userEmailAddress});
    if (apiResponse) {
      yield put(getBillingReportSuccess(apiResponse.response.data));
      yield put(resetLoader());
  }
}

export function* getCallPerformanceReportHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  const userEmailAddress = store.getState().authentication.userId;

  yield put(setLoader());
  const apiResponse = yield call(getCallPerformanceReport, {...action.payload, UserEmailId: userEmailAddress});
    if (apiResponse) {
      yield put(getCallPerformanceReportSuccess(apiResponse.response.data));
      yield put(resetLoader());
  }
}


export function* emailBillingReportHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  const userEmailAddress = store.getState().authentication.userId;

  yield put(setLoader());
  const apiResponse = yield call(emailBillingReport, {...action.payload, UserEmailId: userEmailAddress});
    if (apiResponse) {
     // yield put(getBillingReportSuccess(apiResponse.response.data));
      yield put(resetLoader());
  }
}

export function* emailCallPerformanceReportHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  const userEmailAddress = store.getState().authentication.userId;

  yield put(setLoader());
  const apiResponse = yield call(emailCallPerformance, {...action.payload, UserEmailId: userEmailAddress});
    if (apiResponse) {
      //yield put(getCallPerformanceReportSuccess(apiResponse.response.data));
      yield put(resetLoader());
  }
}
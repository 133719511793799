import { createSlice } from '@reduxjs/toolkit';

const initialState ={
    acsId:'',
    acsToken: ''
};

const communicationSlice = createSlice({
    name:'communication',
    initialState,
    reducers: {
        getAcsToken(state,action) {},
        getAcsTokenSuccess(state, action) {
            let data = {
                acsId: action.payload.acsTokenModel.id,
                acsToken: action.payload.acsTokenModel.token,
            };
            return { ...state, ...{ ...data } };
        }
    },
});

export const { getAcsToken, getAcsTokenSuccess } =
communicationSlice.actions;

export default communicationSlice.reducer;

//@flow
export type authTypes = {
  authentication: {
    success: boolean;
    userDetails: string;
  };
};

export const devToken =
  'eyJFbWFpbEFkZHJlc3MiOiJqb2huLnNtaXRoQGdtYWlsLmNvbSIsIkZpcnN0TmFtZSI6IkpvaG4iLCJMYXN0TmFtZSI6IlNtaXRoIiwiUGhvbmVOdW1iZXIiOiIwNDEyMzQ1Njc4IiwiVXNlck9yZ2FuaXNhdGlvbkRldGFpbHMiOlt7Ik9yZ2FuaXNhdGlvbklkIjoiMTUwYmE1ZjYtNmIxYS00ODkxLThjOGQtZDQxOWE1YzRmMjlmIiwiUm9sZUlkIjoiMGNiMDFjNTktOWMyZS00NTFjLWE5NzMtYmZmY2YzNmRhY2MzIiwiTmFtZSI6IlZpc2lvblJlIFBhcnRuZXJzIiwiVXNlclR5cGUiOiJWUkNVc2VyIn1dLCJVc2VyTG9naW5EYXRlVGltZSI6IjIwMjItMDgtMTdUMDY6Mjg6MzguNDk1MDQwNFoifQ==';
export const orgIntialData = {};

export const defaultGUID = '00000000-0000-0000-0000-000000000000';

export const validateDefaultGUID = (value: any) => {
  if (!!value) {
    if (value === defaultGUID) {
      return '';
    } else {
      return value;
    }
  }
};

export const validateGUID = (value: any) => {
  if (!!value) {
    if (value !== defaultGUID) {
      return value;
    }
  }
};

export const FormNames = {
  UserForm: 'UserForm',
};

export const Roles = {
  Admin: 'Admin',
  User: 'User',
  Prescriber: 'Prescriber',
  NurseInjector: 'NurseInjector',
  NursePractitioner: 'NursePractitioner',
  NoRoleAssigned: 'NoRoleAssigned',
  Reception:'Reception',
};

export const pronouns = [
  { value: 'She/Her/Hers', label: 'She/Her/Hers', Id: 1 },
  { value: 'He/Him/His', label: 'He/Him/His', Id: 2 },
  { value: 'They/Them/Theirs', label: 'They/Them/Theirs', Id: 3 },
];

export const birthGenders = [
  { value: 'Female', label: 'Female', Id: 1 },
  { value: 'Male', label: 'Male', Id: 2 },
  {
    value: 'Indeterminate / Intersex / Unspecified',
    label: 'Indeterminate / Intersex / Unspecified',
    Id: 3,
  },
];

export const genderIdentities = [
  { value: 'Female', label: 'Female', Id: 1 },
  { value: 'Male', label: 'Male', Id: 2 },
  { value: 'Non Binary', label: 'Non Binary', Id: 3 },
  { value: 'Gender Diverse', label: 'Gender Diverse', Id: 4 },
  { value: 'Transgender', label: 'Transgender', Id: 5 },
  { value: 'Different Identity', label: 'Different Identity', Id: 5 },
  { value: 'Other', label: 'Other', Id: 5 },
];

export const states = [
  { Id: 1, value: 'ACT', label: 'Australian Capital Territory (ACT)' },
  { Id: 2, value: 'NSW', label: 'New South Wales (NSW)' },
  { Id: 3, value: 'NT', label: 'Northern Territory (NT)' },
  { Id: 4, value: 'QLD', label: 'Queensland (QLD)' },
  { Id: 5, value: 'SA', label: 'South Australia (SA)' },
  { Id: 6, value: 'TAS', label: 'Tasmania (TAS)' },
  { Id: 7, value: 'VIC', label: 'Victoria (VIC)' },
  { Id: 8, value: 'WA', label: 'Western Australia (WA)' },
];

export const brands = [
  { Id: 1, value: '1', label: 'Silk Laser Clinics', name:'silklaserclinics' },
  { Id: 2, value: '2', label: 'Australian Skin Clinics',  name:'australianskinclinics'},
  { Id: 3, value: '3', label: 'Eden Laser Clinics' ,  name:'edenlaserclinics'},
];

// To be delete, It's come from API
export const products = [
  {
    Id: 15,
    Name: 'Botox - Botulinum Toxin 2.5ml/ 5ml normal saline',
    Manufacturer: null,
    AvailableFor: null,
    Category: 'botox',
  },
  {
    Id: 16,
    Name: 'Dermafrac Exfoliating Tip - Blue',
    Manufacturer: 'juvederm',
    AvailableFor: 'Edenlaserclinics',
    Category: 'dysport',
  },
  {
    Id: 18,
    Name: 'SILK Vitamin C Serum 30ml',
    Manufacturer: 'juvederm',
    AvailableFor: 'australianskinclinics',
    Category: 'other',
  },
  {
    Id: 19,
    Name: 'BALENSE Normal Skin Moisturiser +HA 50ml',
    Manufacturer: null,
    AvailableFor: null,
    Category: 'botox',
  },
];

// To be delete, It's come from API
export const treatmentAreas = [
  {
    Category: 'botox',
    Areas: [
      { Id: 10, Name: 'Glabella', Code: 'glabella', Dosage: '40u' },
      { Id: 11, Name: 'Orbic Oculi', Code: 'orbicOculi', Dosage: '40u' },
      { Id: 12, Name: 'Frontalis', Code: 'frontalis', Dosage: '25u' },
      { Id: 13, Name: 'DAO', Code: 'dao', Dosage: '10u' },
      { Id: 14, Name: 'Mentalis', Code: 'mentalis', Dosage: '10u' },
      { Id: 15, Name: 'Masseter', Code: 'masseter', Dosage: '100u' },
      { Id: 16, Name: 'Platysma', Code: 'platysma', Dosage: '30u' },
      {
        Id: 17,
        Name: 'Orbicularis Oris',
        Code: 'orbicularisOris',
        Dosage: '8u',
      },
      { Id: 18, Name: 'Nasalis', Code: 'nasalis', Dosage: '8u' },
      { Id: 19, Name: 'LLSAN', Code: 'llsan', Dosage: '20u' },
      { Id: 20, Name: 'Hyperhydrosis', Code: 'hyperhydrosis', Dosage: '150u' },
    ],
  },
  {
    Category: 'dysport',
    Areas: [
      { Id: 20, Name: 'Glabella', Code: 'glabella', Dosage: '100u' },
      { Id: 21, Name: 'Orbic Oculi', Code: 'orbicOculi', Dosage: '100u' },
      { Id: 22, Name: 'Frontalis', Code: 'frontalis', Dosage: '62u' },
      { Id: 23, Name: 'DAO', Code: 'dao', Dosage: '25u' },
      { Id: 24, Name: 'Mentalis', Code: 'mentalis', Dosage: '25u' },
      { Id: 25, Name: 'Masseter', Code: 'masseter', Dosage: '250u' },
      { Id: 26, Name: 'Platysma', Code: 'platysma', Dosage: '75u' },
      {
        Id: 27,
        Name: 'Orbicularis Oris',
        Code: 'orbicularisOris',
        Dosage: '20u',
      },
      { Id: 28, Name: 'Nasalis', Code: 'nasalis', Dosage: '20u' },
      { Id: 29, Name: 'LLSAN', Code: 'llsan', Dosage: '50u' },
    ],
  },
  {
    Category: 'other',
    Areas: [
      { Id: 80, Name: 'Cheek - Upper', Code: 'CheekUpper', Dosage: '2ml' },
      { Id: 81, Name: 'Cheek - Lower', Code: 'CheekLower', Dosage: '2ml' },
      { Id: 82, Name: 'Nasolabial Fold', Code: 'NasolabialFold', Dosage: '2ml' },
      { Id: 83, Name: 'Chin', Code: 'Chin', Dosage: '2ml' },
      { Id: 84, Name: 'Jaw', Code: 'Jaw', Dosage: '2ml' },
      { Id: 85, Name: 'Temple', Code: 'Temple', Dosage: '2ml' },
      { Id: 86, Name: 'Lips', Code: 'Lips', Dosage: '2ml' },
      {
        Id: 87,
        Name: 'Periorbital',
        Code: 'Periorbital',
        Dosage: '1ml',
      },
      { Id: 88, Name: 'Peri Oral Lines', Code: 'PeriOralLines', Dosage: '2ml' },
      { Id: 89, Name: 'Marionette Lines', Code: 'MarionetteLines', Dosage: '2ml' },
      { Id: 90, Name: 'HydroBoost', Code: 'HydroBoost', Dosage: '3ml' },
      { Id: 91, Name: 'Hand', Code: 'Hand', Dosage: '1ml per hand' },
      { Id: 92, Name: 'Earlobe', Code: 'Earlobe', Dosage: '0.2ml per ear' },
    ],
  },
];

// To be delete, it's come from API

export const DrugBookList = [
  {
    Id: 1,
    Type: 'Received',
    ManufacturerName: 'Juvaderm',
    Product: 'Voluma 30',
    Qty: '25',
    BatchNumber: 'BN1234567890',
    Clinic: 'HydePark',
    Patient: 'N/A',
    CapturedBy: 'Ashlee Andrews',
    Date: '28 April 2023',
    AdministeredDate: '28 April 2023',
  },
  {
    Id: 2,
    Type: 'Administered',
    ManufacturerName: 'Juvaderm',
    Product: 'Voluma 30',
    Qty: '25',
    BatchNumber: 'BN1234567890',
    Clinic: 'Warehouse',
    Patient: 'N/A',
    CapturedBy: 'Ashlee Andrews',
    Date: '28 April 2023',
  },
  {
    Id: 3,
    Type: 'Received',
    ManufacturerName: 'Juvaderm',
    Product: 'Voluma 30',
    Qty: '25',
    BatchNumber: 'BN1234567890',
    Clinic: 'West Lakes',
    Patient: 'N/A',
    CapturedBy: 'Ashlee Andrews',
    Date: '28 April 2023',
  },
  {
    Id: 4,
    Type: 'Administered',
    ManufacturerName: 'Juvaderm',
    Product: 'Voluma 30',
    Qty: '25',
    BatchNumber: 'BN1234567890',
    Clinic: 'HydePark',
    Patient: 'N/A',
    CapturedBy: 'Ashlee Andrews',
    Date: '28 April 2023',
  },
];

export const heardAboutSilk = [
  { Id: 1, value: 'Another Client', label: 'Another Client' },
  { Id: 2, value: 'Internet search', label: 'Internet search' },
  { Id: 3, value: 'Social Media', label: 'Social Media' },
  { Id: 4, value: 'Word of mouth', label: 'Word of mouth' },
  { Id: 5, value: 'Walk by', label: 'Walk by' },
  { Id: 6, value: 'Internet ad', label: 'Internet ad' },
  { Id: 7, value: 'Radio', label: 'Radio' },
  { Id: 8, value: 'TV', label: 'TV' },
];

export const learnAbout = [
  { Id: 1, value: 'Skin analysis & care', label: 'Skin analysis & care' },
  { Id: 2, value: 'Body sculpting', label: 'Body sculpting' },
  { Id: 3, value: 'Laser hair removal', label: 'Laser hair removal' },
  { Id: 4, value: 'Anti-wrinkle treatments', label: 'Anti-wrinkle treatments' },
  { Id: 5, value: 'Dermal fillers', label: 'Dermal fillers' },
  { Id: 6, value: 'Thread treatments', label: 'Thread treatments' },
  { Id: 7, value: 'PRP', label: 'PRP' },
  {
    Id: 8,
    value: 'Bio remodelling treatments',
    label: 'Bio remodelling treatments',
  },
  { Id: 9, value: 'Nothing, thank you', label: 'Nothing, thank you' },
];

// To be delete, it's come from API
export const availablePrescribers = [
  {
    id: 1,
    name: 'Ashlee Andrews',
    email: 'name@silklaser.com.au',
    home_clinic: 'Double Bay',
  },
  {
    id: 2,
    name: 'Brianna Smith',
    email: 'name@silklaser.com.au',
    home_clinic: 'Norwood',
  },
  {
    id: 3,
    name: 'Eliza Whitaker',
    email: 'name@silklaser.com.au',
    home_clinic: 'Green Hills',
  },
  {
    id: 4,
    name: 'Laura Boucher',
    email: 'name@silklaser.com.au',
    home_clinic: 'Double Bay',
  },
  {
    id: 5,
    name: 'Leena Santos',
    email: 'name@silklaser.com.au',
    home_clinic: 'Double Bay',
  },
];

export const AdverseEventList = [
  {
    Id: 1,
    Patient: 'Ashlee ******',
    Date: 'Date',
    Clinic: 'Clinic',
    PrescribingDoctor: 'Prescribing Doctor',
    Products: 'Products',
  },
  {
    Id: 1,
    Patient: 'Ashlee ******',
    Date: 'Date',
    Clinic: 'Clinic',
    PrescribingDoctor: 'Prescribing Doctor',
    Products: 'Products',
  },
  {
    Id: 1,
    Patient: 'Ashlee ******',
    Date: 'Date',
    Clinic: 'Clinic',
    PrescribingDoctor: 'Prescribing Doctor',
    Products: 'Products',
  },
  {
    Id: 1,
    Patient: 'Ashlee ******',
    Date: 'Date',
    Clinic: 'Clinic',
    PrescribingDoctor: 'Prescribing Doctor',
    Products: 'Products',
  },
];

export const ApprovalStatus = [
  {
    id: 1,
    status: 'DT',
    description: 'In progress Application',
  },
  {
    id: 2,
    status: 'NI',
    description: 'Approved by Nurse Injector',
  },
  {
    id: 3,
    status: 'NP',
    description: 'Approved by Nurse Practitioner',
  },
  {
    id: 4,
    status: 'AP',
    description: 'Approved by Prescriber',
  },
  {
    id: 5,
    status: 'AC',
    description: 'Approved by Client',
  },
  {
    id: 6,
    status: 'CW',
    description: 'Cancel to rework',
  },
  {
    id: 7,
    status: 'D',
    description: 'Decline',
  },
];

export const RoleBasedApprovalStatus = [
  {
    id: 2,
    status: 'NI',
    description: 'Approved by Nurse Injector',
    role: 'NurseInjector',
  },
  {
    id: 3,
    status: 'NP',
    description: 'Approved by Nurse Practitioner',
    role: 'NursePractitioner',
  },
  {
    id: 4,
    status: 'AP',
    description: 'Approved by Prescriber',
    role: 'Prescriber',
  },
];

// To be delete, it's come from API
export const billing = [
  {
    id: 1,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 2,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 3,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 4,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 5,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 6,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
];

// To be delete, it's come from API
export const callPerformance = [
  {
    id: 1,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 2,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 3,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 4,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 5,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
  {
    id: 6,
    callID: '0000-0000-0000',
    clinic: 'Double Bay',
    nurse: 'Ashlee Reilly',
    prescriber: 'Nicolas Jamieson',
    date: '8 May 2023, 12:04',
    waited: '01:56',
    duration: '02:47',
    appointment: '0000-0000-0000',
  },
];

// To be delete, it's come from API
export const callsInQueque = [
  {
    id: 1,
    patientName: 'Leah Whiting',
    pronouns: 'She/Her/Hers',
    gender: 'Female',
    nurseName: 'Ashlee Andrews',
    location: 'Balmain',
    isDirectCall: false,
  },
];

export const packageTypes = {
  injectibles: 'injectibles',
  prp: 'prp',
  threads: 'threads',
};

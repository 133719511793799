import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const adverseEventsSlice = createSlice({
  name: 'adverseEvents',
  initialState,
  reducers: {
    getAdverseEvent(state, action) {},
    getAdverseEvents(state, action) {},
    getAdverseEventSuccess(state, action) {
        return { ...state, ...action.payload  };
      },
    getAdverseEventsSuccess(state, action) {
        let adverseEvents = {
            adverseEvents: Array.isArray(action.payload.AdverseEvents)
              ? [...action.payload.AdverseEvents]
              : [],
            totalCount: action.payload.TotalCount || 0,
          };
          return { ...state, ...adverseEvents };
    },
    createAdverseEvent(state,action){},
    createAdverseEventSuccess(state, action) {
      const data = action.payload;
      return { ...state, ...data };
  },
  navigateToAdverseEvent(state,action){},
  },
});

export const {navigateToAdverseEvent,getAdverseEvents, getAdverseEvent, getAdverseEventSuccess, getAdverseEventsSuccess, createAdverseEvent, createAdverseEventSuccess } =
adverseEventsSlice.actions;

export default adverseEventsSlice.reducer;

import { call, put } from 'redux-saga/effects';
import { addDrugBookAdministeredSuccess, addReceivedDrugBookSuccess, addRemovedDrugBookSuccess, getDrugBookAdministeredSuccess, getDrugBookReceivedSuccess, getDrugBookRemovedSuccess } from '../reducers/drugbookSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { addDrugBookAdministered, addReceivedDrugBook, addRemovedDrugBook, getDrugBookAdministered, getDrugBookReceived, getDrugBookRemoved } from '../api/drugbookApi';

export function* getDrugBookReceivedHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
  const { response } = yield call(getDrugBookReceived, {data});
  if (response) {
    yield put(getDrugBookReceivedSuccess(response.data));
  }
}

export function* getDrugBookRemovedHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
  const { response } = yield call(getDrugBookRemoved, {data});
  if (response) {
    yield put(getDrugBookRemovedSuccess(response.data));
  }
}

export function* getDrugBookAdministeredHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
    const { response } = yield call(getDrugBookAdministered, {data});
    if (response) {
      yield put(getDrugBookAdministeredSuccess(response.data));
    }
  }

  export function* addDrugBookAdministeredHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
  yield put(setLoader());
    const { response } = yield call(addDrugBookAdministered, action.payload);
    if (response) {
    const { response } = yield call(getDrugBookAdministered, {data});
    if(response){
        yield put(addDrugBookAdministeredSuccess(response.data));
        yield put(resetLoader());
    }
    }
}
export function* addReceivedDrugBookHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
    yield put(setLoader());
      const { response } = yield call(addReceivedDrugBook, action.payload);
      if (response) {
      const { response } = yield call(getDrugBookReceived, {data:data});
      if(response){
          yield put(addReceivedDrugBookSuccess(response.data));
          yield put(resetLoader());
      }
      }
  }

  export function* addRemovedDrugBookHandler(action: any): Generator<any, any, any> {
    const {data} = action.payload;
    yield put(setLoader());
      const { response } = yield call(addRemovedDrugBook, action.payload);
      if (response) {
      const { response } = yield call(getDrugBookRemoved, {data:data});
      if(response){
          yield put(addRemovedDrugBookSuccess(response.data));
          yield put(resetLoader());
        }
      }
  }
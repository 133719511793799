import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
};

const userClinicSlice = createSlice({
  name: 'userClinic',
  initialState,
  reducers: {
    getUserClinic(state, action) {},
    getUserClinicSuccess(state, action) {
      let userClinic = {
        ...action.payload,
      };
      return { ...state, ...userClinic };
    },
  },
});

export const { getUserClinic, getUserClinicSuccess } = userClinicSlice.actions;

export default userClinicSlice.reducer;

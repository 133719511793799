import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  isLoader: false,
};

const loaderSlice = createSlice({
  name: 'Loader',
  initialState,
  reducers: {
    setLoader(state) {
      return { ...state, ...{ isLoader: true } };
    },
    resetLoader(state) {
      return { ...state, ...initialState };
    },
  },
});

export const { setLoader, resetLoader } = loaderSlice.actions;

export default loaderSlice.reducer;

import { post, get } from "./restApi";
import { envEndPoints } from "../config";

export const getAdverseEvents = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/AdverseEvent/getAdverseEvents`,
    data: req.data,
  });

export const getAdverseEvent = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/AdverseEvent/${req.id}`
  });

export const createAdverseEvent = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/AdverseEvent`,
    data: req.data,
  });

import { devToken } from '../../constants/index';

export const reqConfig = (config) => {
  const localserver = 'http://localhost:3006';

  if (config.url.includes('/getUserRole')) {
    config.method = 'get';
    config.url = `${localserver}/getUserRole`;
  } else if (config.url.includes('/getUserList')) {
    config.method = 'get';
    config.url = `${localserver}/getUserList`;
  } else if (config.url.includes('/clinic')) {
    config.method = 'get';
    config.url = `${localserver}/clinic`;
  } else if (
    config.url.includes('/product') &&
    !config.url.includes('/product/')
  ) {
    config.method = 'get';
    config.url = `${localserver}/product`;
  } else if (config.url.includes('/getAppointmentList')) {
    config.method = 'get';
    config.url = `${localserver}/getAppointmentList`;
  } else if (config.url.includes('appointmentdetails')) {
    config.method = 'get';
    config.url = `${localserver}/appointmentdetails`;
  }

  return config;
};

export const resConfig = (response) => {
  const devResponse = response;

  devResponse.headers['x-access-token'] = devToken;
  devResponse.headers['x-organisation-id'] =
    'b390f291-d062-488b-875c-bb5a75722632';
  devResponse.headers['x-asset-id'] = 'b390f291-d062-488b-875c-bb5a75722632';

  return devResponse;
};

import { call, put } from 'redux-saga/effects';
import {
  getAppointment,
  deleteAppointnment,
  createAppointment,
  saveAppointmentAdditionalDetails,
  confirmAppointmentTreatmentsAPI,
  refreshAppointment,
  createLocalAppointment,
  confirmTreatment,
  submitDetails,
  saveDischargeSummary,
  searchAppointments,
  saveMedicalHistory
} from '../api/appointmentsApi';
import {
  getAppointmentSuccess,
  getAppointmentDetailsSuccess,
  saveAppointmentAdditionalDetailsSuccess,
  refreshAppointmentSuccess,
  searchAppointmentsSuccess,
  saveMedicalHistoryDetailsSuccess
} from '../reducers/appointmentSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { appointmentDetails } from '../receivers/appointmentDetailsReceiver';
import { getDischargeSummaries } from '../receivers/dischargeSummaryReceiver';
import { getAcsToken } from './../api/communicationApi';
import { getAcsTokenSuccess } from '../reducers/communicationSlice';
import store from '../store';
import { Roles } from '../../constants';
import moment from 'moment-timezone';
import Moment from 'moment';

export function* searchAppointmentsHandler(action: any): Generator<any, any, any> {
  const {data} = action.payload;
  yield put(setLoader());
  const { response } = yield call(searchAppointments, {data});
  if (response) {
    yield put(searchAppointmentsSuccess(response.data));
    yield put(resetLoader());
  }
}

export function* getAppointmentHandler(action: any): Generator<any, any, any> {
  const { id, navigate, redirectUrl } = action.payload;
  const autenticationSate = store.getState().authentication;
  yield put(setLoader());
  const { response } = yield call(getAppointment, { id: id });
  if (response) {
    yield put(getAppointmentDetailsSuccess(appointmentDetails(response.data)));

    if (autenticationSate.role === Roles.Prescriber || autenticationSate.role === Roles.NurseInjector || autenticationSate.role === Roles.NursePractitioner)
    {
      const accessTokenResponse = yield call(getAcsToken, { id: autenticationSate.id });
        if (accessTokenResponse) {        
          yield put(getAcsTokenSuccess({ ...accessTokenResponse.response.data }));
        }
    }

    yield put(resetLoader());
    navigate(redirectUrl);
  }
}

export function* deleteAppointmentHandler(
  action: any
): Generator<any, any, any> {
  const { id, history } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(deleteAppointnment, { id: id });
  if (deleteResponse) {
    const { response } = yield call(searchAppointments, {});
    if (response) {
      yield put(getAppointmentSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* createAppointmentHandler(
  action: any
): Generator<any, any, any> {
  const { id, history } = action.payload;
  yield put(setLoader());
  const createResponse = yield call(createAppointment, { id: id });
  if (createResponse) {
    const { response } = yield call(searchAppointments, {});
    if (response) {
      yield put(getAppointmentSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* saveAppointmentAdditionalDetailsHandler(
  action: any
): Generator<any, any, any> {
  const { id, AppointmentAdditionalDetails, history } = action.payload;

  const autenticationSate = store.getState().authentication;

 
  yield put(setLoader());
  const createResponse = yield call(saveAppointmentAdditionalDetails, {
    data: AppointmentAdditionalDetails,
    id: id,
    userId: autenticationSate.id,
  });
  if (createResponse) {
    yield put(
      saveAppointmentAdditionalDetailsSuccess({
        AppointmentAdditionalDetails: AppointmentAdditionalDetails,
      })
    );
    yield put(resetLoader());
  }
}

export function* confirmAppointmentTreatmentsHandler(
  action: any
): Generator<any, any, any> {
  const { appointmentId, treatmentplansId } = action.payload;
  yield put(setLoader());
  const request = {
    appointmentId: appointmentId,
    treatmentplansId: treatmentplansId,
  };

  const response = yield call(confirmAppointmentTreatmentsAPI, request);
  if (response) {

    //Reload Appointment
    const  appointmentResponse  = yield call(getAppointment, { id: appointmentId });
    if (appointmentResponse) {
      yield put(getAppointmentDetailsSuccess(appointmentDetails(appointmentResponse.response.data)));
    }

    yield put(resetLoader());
  }
}

export function* refreshAppointmentHandler(
  action: any
): Generator<any, any, any> {
  const { data } = action.payload;
  yield put(setLoader());
  const { response } = yield call(refreshAppointment, { data: data });
  if (response) {    
    yield put(refreshAppointmentSuccess(response.data));
    yield put(resetLoader());
  }
}

export function* createLocalAppointmentHandler(
  action: any
): Generator<any, any, any> {  
  const { data, navigate, redirectUrl } = action.payload;
  yield put(setLoader());
  const { response } = yield call(createLocalAppointment, { data: data });
  if (response) {        
    yield put(resetLoader());
    navigate(redirectUrl);
  }
}

export function* confirmTreatmentHandler(
  action: any
): Generator<any, any, any> {  
  const { data } = action.payload;
  yield put(setLoader());
  const { response } = yield call(confirmTreatment, { data });
  if (response) {        
    yield put(resetLoader());
  }
}

export function* submitHandler(
  action: any
): Generator<any, any, any> {  
  const { data, navigate,  redirectUrl} = action.payload;

  const dischargeRequest = getDischargeSummaries(data);
 
  yield put(setLoader());

  var bodyFormData = new FormData();

  if(data.files.length > 0)
  {
    for (var i = 0; i < data.files.length; i++) {
      bodyFormData.append('FileDetails', data.files[i]);
    }
  }

 const {response} = yield call(saveDischargeSummary, { data : dischargeRequest});
  
  if (response) {   
    const submitResponse  = yield call(submitDetails, { appointmentId: data.appointmentId, data: bodyFormData })   
    if(submitResponse)
    {
      var s =  submitResponse.response.data.AppointmentId;


      // {"IsSuccess":true,"AppointmentStatus":1,"AppointmentId":7}
      navigate('/appointments/complete');
    }
    
    yield put(resetLoader());
  }
}

export function* saveMedicalHistoryHandler(
  action: any
): Generator<any, any, any> {  
  const { patientId, appointmentId, userId, medicalHistoryId, medicalHistoryData, navigate, redirectUrl , role} = action.payload;
  
if(!!medicalHistoryData.CosmeticInjection_LastTreatment && medicalHistoryData.CosmeticInjection_LastTreatment!=='')
{
  medicalHistoryData.CosmeticInjection_LastTreatment = moment(medicalHistoryData.CosmeticInjection_LastTreatment).format('DD/MM/YYYY');
}
if(!!medicalHistoryData.SurgicalProcedures_Date && medicalHistoryData.SurgicalProcedures_Date!=='')
{
  medicalHistoryData.SurgicalProcedures_Date = moment(medicalHistoryData.SurgicalProcedures_Date).format('DD/MM/YYYY');
}



  yield put(setLoader());
  const { response } = yield call(saveMedicalHistory, {  appointmentId: appointmentId,
    patientId: patientId,
    userId: userId,
    medicalHistoryId: medicalHistoryId,
    medicalHistoryData: medicalHistoryData} );
  if (response) {   
    const medicalHistory ={
        ...medicalHistoryData,
        Id: response.data.Id
    };

    yield put(saveMedicalHistoryDetailsSuccess({medicalHistory}));
    yield put(resetLoader());

    if(role === Roles.Reception)
    {
      navigate(redirectUrl);
    }
  }
}


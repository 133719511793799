import { post, put, del } from './restApi';
import { envEndPoints } from '../config';

export const getProductsApi = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/product`,
    data: req.data
  });

export const deleteProductApi = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/product/${req.id}`,
  });

export const updateProductApi = (req: any) =>
  put({
    url: `${envEndPoints().baseUrl}/product/updateProduct`,
    data: req,
  });

import { call, put } from 'redux-saga/effects';
import { createAdverseEvent, getAdverseEvent, getAdverseEvents } from '../api/adverseEventsApi';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { getAdverseEventSuccess, getAdverseEventsSuccess, navigateToAdverseEvent } from '../reducers/adverseEventsSlice';
import { getDrugBookAdministeredSuccess } from '../reducers/drugbookSlice';
import { getDrugBookAdministered } from '../api/drugbookApi';

export function* getAdverseEventsHandler(action: any): Generator<any, any, any> {
  const { data} = action.payload;
  const { response } = yield call(getAdverseEvents, {data});
  if (response) {
    yield put(getAdverseEventsSuccess(response.data));
  }
}

export function* getAdverseEventHandler(action: any): Generator<any, any, any> {
  const { id, navigate, redirectUrl} = action.payload;
  yield put(setLoader());
  const { response } = yield call(getAdverseEvent, {id});
  if (response) {
    yield put(getAdverseEventSuccess(response.data));
    yield put(resetLoader());
    navigate(redirectUrl);
  }
}
export function* createAdverseEventHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl} = action.payload;
  yield put(setLoader());
  const { response } = yield call(createAdverseEvent, {data});
  if (response) {
    const { response } = yield call(getAdverseEvents, {data: {}});
    if (response) {
      yield put(getAdverseEventsSuccess(response.data));
      yield put(resetLoader());
      navigate(redirectUrl);
    }
  }
}

export function* navigateToAdverseEventHandler(action: any): Generator<any, any, any> {
  const {data, navigate, redirectUrl} = action.payload;
    const { response } = yield call(getDrugBookAdministered, {data});
    if (response) {
      yield put(getDrugBookAdministeredSuccess(response.data));
      navigate(redirectUrl);
    }
  }
import { post, get } from './restApi';
import { envEndPoints } from '../config';

export const createTreatmentPlan = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/treatment/create`,
    data: req.data,
  });

  export const approveTreatmentPlan = (req: any) => 
  post({
    url: `${envEndPoints().baseUrl}/treatment/approve`,
    data: req.data,
  });

  export const getTreatmentPlan = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/treatment/details/${req.id}`,
  });

  export const patientConsent = (req: any) => 
  post({
    url: `${envEndPoints().baseUrl}/treatment/patientConsent`,
    data: req.data,
  });

export const declineTreatmentPlan = (req: any) => 
  post({
    url: `${envEndPoints().baseUrl}/treatment/decline`,
    data: req.data,
  });

  export const generateDocuments = (req: any) => 
  get({
    url: `${envEndPoints().baseUrl}/report/generateDocuments/${req.id}`,
  });
import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  isLoaded: false,
  isSubmitted: false,
};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    searchAppointments(state, action) {},
    searchAppointmentsSuccess(state, action) {
      let appointments = {
        appointments: Array.isArray(action.payload.Appointments)
          ? [...action.payload.Appointments]
          : [],
        totalCount: action.payload.TotalCount || 0,
        isLoaded: true,
      };
      return { ...state, ...appointments };
    },
    getAppointment(state, action) {},
    getAppointmentSuccess(state, action) {
      let appointments = {
        appointments: Array.isArray(action.payload.Appointments)
          ? [...action.payload.Appointments]
          : [],
        totalCount: action.payload.TotalCount || 0,
        isLoaded: true,
      };
      return { ...state, ...appointments };
    },
    deleteAppointment(state, action) {},

    createAppointment(state, action) {},
    getAppointmentDetailsSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    saveAppointmentAdditionalDetailsSuccess(state, action) {
      let appointmentAdditionalDetails = {
        appointmentAdditionalDetails: {
          ...action.payload.AppointmentAdditionalDetails,
        },
      };
      return { ...state, ...appointmentAdditionalDetails };
    },
    saveAppointmentAdditionalDetails(state, action) {},
    confirmAppointmentTreatments(state, action) {},
    confirmAppointmentTreatmentsSucess(state, action) {},
    refreshAppointment(state, action) {},
    refreshAppointmentSuccess(state, action) {
      let zenotiAppointment = {
        ...action.payload.Appointments        
      }
      return { ...state, ...zenotiAppointment};
    },
    createLocalAppointment(state, action) {},
    confirmTreatment(state,action){},
    submitDetails(state, action) {},

    submitDetailsSuccess(state, action) {

      return { ...state, ...{isSubmitted: true} };
    },
    saveMedicalHistoryDetailsSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    saveMedicalHistoryDetails(state, action) {},
  },
});

export const {
  getAppointmentSuccess,
  deleteAppointment,
  getAppointment,
  createAppointment,
  getAppointmentDetailsSuccess,
  saveAppointmentAdditionalDetailsSuccess,
  saveAppointmentAdditionalDetails,
  confirmAppointmentTreatments,
  confirmAppointmentTreatmentsSucess,
  refreshAppointment,
  refreshAppointmentSuccess,
  createLocalAppointment,
  confirmTreatment,
  submitDetails,
  searchAppointments,
  searchAppointmentsSuccess,
  saveMedicalHistoryDetailsSuccess,
  saveMedicalHistoryDetails

} = appointmentSlice.actions;

export default appointmentSlice.reducer;

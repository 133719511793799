import { post, get, put, del } from './restApi';
import { envEndPoints } from '../config';
import { authentiationHandler } from './errorHandler';

export const authenticateUser = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/api/user/authenticateUser`,
    data: req,
    errorHandler: authentiationHandler,
  });

export const getUsersList = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/user/getUserList/${req.id}`,
  });

export const deleteUser = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/user/${req.id}`,
  });

export const updateUser = (req: any) =>
  put({
    url: `${envEndPoints().baseUrl}/user/updateUser`,
    data: req,
  });

export const addUser = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/user/createUserManually`,
    data: req,
  });

export const getUserRolesApi = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/user/getUserRoles`,
  });

  export const getUserClinic = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/user/getUserClinic/${req.userId}`,
  });

  export const getAvailablePrescribers = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/user/getAvailablePrescribers`,
  });

  export const getNurses = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/user/getNurses`,
  });

  export const saveUserSign = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/user/userSign/${req.userId}`,
    data: req.data,
  });
import React from 'react';
import Select, { components } from "react-select";
import './SelectBox.css';

const SelectBox = React.forwardRef((props, ref) => {
  const { label, options, readOnly=false, error="", ...otherProps } = props;
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <div className='position-relative'>
      <Select
        className={`theme-select ${error && 'invalid'}`}
        classNamePrefix="select"
        options={options}
        isSearchable={false}
        isClearable={false}
        hideSelectedOptions={false}
        isDisabled={readOnly}
        ref={ref}
        components={{
          ValueContainer: CustomValueContainer
        }}
        placeholder={label}
        styles={{
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -6 : 2,
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) ? 14 : 18
          })
        }}
        {...otherProps}
      />
      {error && <span className="errors-message">{error}</span>}
    </div>
  )
});

export default SelectBox;
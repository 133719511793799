import { call, put } from 'redux-saga/effects';
import {
  getUsersList,
  deleteUser,
  updateUser,
  addUser,
  getUserRolesApi,
  getUserClinic,
  getAvailablePrescribers,
  getNurses
} from '../api/userApi';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { getUserListSuccess,getAvailablePrescribersSuccess, getNursesSuccess } from '../reducers/userSlice';
import { getUserRoleSuccess } from '../reducers/userRolesSlice';
import { getUserClinicSuccess } from '../reducers/userClinicSlice';

export function* getUsersListHandler(action: any): Generator<any, any, any> {
  const { loginUserId, type, history } = action.payload;

  const { response } = yield call(getUsersList, { id: loginUserId });
  if (response) {
    yield put(getUserListSuccess(response.data));
  }
}

export function* deleteUserHandler(action: any): Generator<any, any, any> {
  const { id, loginUserId, history } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(deleteUser, { id: id });
  if (deleteResponse) {
    const { response } = yield call(getUsersList, { id: loginUserId });
    if (response) {
      yield put(getUserListSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* updateUserHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  yield put(setLoader());
  const updateResponse = yield call(updateUser, action.payload);
  if (updateResponse) {
    const { response } = yield call(getUsersList, {id: action.payload.loginUserId});
    if (response) {
      yield put(getUserListSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* addUserHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  yield put(setLoader());
  const addUserResponse = yield call(addUser, action.payload);
  if (addUserResponse) {
    const { response } = yield call(getUsersList, {id: action.payload.loginUserId});
    if (response) {
      yield put(getUserListSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* getUserRolesHandler(action: any): Generator<any, any, any> {
  const { reqData, type, history } = action.payload;

  const { response } = yield call(getUserRolesApi, {});
  if (response) {
    yield put(getUserRoleSuccess(response.data));
  }
}

export function* getUserClinicHandler(action: any): Generator<any, any, any> {
  const { userId } = action.payload;
  const { response } = yield call(getUserClinic, {userId: userId });
  if (response) {
    yield put(getUserClinicSuccess(response.data));
  }
}

export function* getAvailablePrescribersHandler(action: any): Generator<any, any, any> {
  const { response } = yield call(getAvailablePrescribers, {});
  if (response) {
    yield put(getAvailablePrescribersSuccess(response.data));
  }
}

export function* getNursesHandler(action: any): Generator<any, any, any> {
  const { response } = yield call(getNurses, {});
  if (response) {
    yield put(getNursesSuccess(response.data));
  }
}